import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('collectionMenu', (props: any) => ({
    atTop: false,
    navPosition: 0,

    init() {
      const child = document.querySelector('[active-menu-item]');
      if (child === null) return;

      const relativePosition = this.getPositionRelativeToParent(child);
      const element = document.querySelector('[collection-menu-container]');
      if (element === null) return;

      element.scrollLeft = relativePosition.left;
    },

    getPositionRelativeToParent(element: any) {
      const parent = element.parentElement;
      const parentRect = parent.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      return {
        top: elementRect.top - parentRect.top,
        left: elementRect.left - parentRect.left,
        bottom: elementRect.bottom - parentRect.top,
        right: elementRect.right - parentRect.left
      };
    }
  }));
};
