export const updatePage = async (url: string, to = null) => {
  const newurl = url;
  const response = await fetch(newurl).then((response) => response.text());

  const html = document.createElement('div');
  html.innerHTML = response;

  const newContent = html.querySelectorAll('[updatable]');

  history.replaceState({}, "", newurl);

  document.body.querySelectorAll('[updatable]').forEach((item, index) => {
    item.innerHTML = newContent[index].innerHTML;
  });

  if (to != null) {
    console.log("to", to)
    const element = document.querySelector(to);
    if (element) {
      element.scrollIntoView();
    }
  }

  const urlWithCollections = window?.LSGSetCollections();
  window?.LSGSortByCollection(undefined, undefined);
};
