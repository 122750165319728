import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('ingredientModal', () => ({
    show: false,
    content: null,

    open(e: any) {
      this.content = e.detail;
      document.getElementsByTagName('html')[0].classList.add("is-ingredient-modal-open");
      this.show = true;
    },
    async close() {
      this.show = false;
      document.getElementsByTagName('html')[0].classList.remove("is-ingredient-modal-open");

      await new Promise(r => setTimeout(r, 300));
      this.content = null;
    }
  }));
};