import { Alpine as AlpineType } from 'alpinejs';

import { getProductRecommendations } from '@/utils/get-product-recommendations';
import { storefrontClient } from '@/utils/storefront-client';

export default (Alpine: AlpineType) => {
  Alpine.store('cart', {
    data: null,
    productRecommendations: [],
    maxCountProductRecommendations: 4,

    async init() {
      await this.load();
    },

    get items() {
      if (!this.data?.items?.length) {
        return [];
      }

      return this.data.items;
    },
    get itemsCount() {
      return this.data?.item_count || 0;
    },
    get freeGift() {
      const propertyKey = (Alpine.store('freeGift') as any).propertyKey;

      return (
        propertyKey &&
        this.data.items.find((item: any) => item.properties[propertyKey])
      );
    },

    async load() {
      const responce = await fetch('/cart.js');
      this.data = await responce.json();

      this.loadProductRecommendation();
    },
    async addItem(item: any, itemPrice = 0) {
      //item.properties['_added_timestamp'] = Date.now().toString();

      const items = [item];

      await fetch('/cart/add.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items }),
      });
    },
    async removeItem(key: string) {
      await fetch('/cart/change.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: key,
          quantity: 0,
        }),
      });

      await this.load();
    },
    async hardcodeAddToCart() {
      await fetch('/cart/add.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: [
            {
              id: 39704654479396,
              quantity: 1
            },
            {
              id: 42204979298340,
              selling_plan: 18234769444,
              quantity: 1
            }
          ]
        }),
      });

      location.assign('/checkout?discount=OGMushroomKit');
    },
    async changeVariant(variantId: string | number, item: any) {
      const cartQuery = `{
        cart(id: "gid://shopify/Cart/${this.data.token}"
        ) {
          lines(first: 100) {
            edges {
              node {
                id
                time: attribute(key: "_added_timestamp") {
                  value
                }
              }
            }
          }
        }
      }`;

      const {
        data: { cart },
      } = await storefrontClient(cartQuery, {});

      if (!cart) {
        console.error('no cart');
        return;
      }

      const id = cart.lines.edges.find(
        ({ node }: any) => node.time?.value === item.properties._added_timestamp
      )?.node?.id;

      if (!id) {
        console.error('no item');
        return;
      }

      const updateQuery = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          userErrors {
            field
            message
          }
        }
      }`;

      const variables = {
        cartId: `gid://shopify/Cart/${this.data.token}`,
        lines: [
          {
            id,
            merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
            attributes: Object.entries(item.properties).map(([key, value]) => ({
              key,
              value,
            })),
          },
        ],
      };

      await storefrontClient(updateQuery, variables);

      await this.load();
    },
    async loadProductRecommendation() {
      if (!this.items.length) {
        this.productRecommendations = [];
        return;
      }

      const results = await Promise.all(
        this.items.map(
          ({ product_id }: { product_id: string }) =>
            new Promise(async resolve => {
              const products = await getProductRecommendations({
                productId: product_id,
                limit: this.maxCountProductRecommendations,
              });
              resolve(products);
            })
        )
      );

      const productRecommendations: any = [];

      for (
        let index = 0;
        index <= this.maxCountProductRecommendations &&
        productRecommendations.length < this.maxCountProductRecommendations;
        index++
      ) {
        for (const productList of results) {
          if (
            productList.length &&
            productList.length >= index + 1 &&
            !productList[index].tags.includes('do-not-recommend') &&
            !productRecommendations.find(
              (item: any) => item.id === productList[index].id
            ) &&
            !this.items.find(
              (item: any) => item.product_id === productList[index].id
            )
          ) {
            productRecommendations.push(productList[index]);

            if (
              productRecommendations.length ===
              this.maxCountProductRecommendations
            ) {
              break;
            }
          }
        }
      }

      const variantsIds = productRecommendations
        .map((product: any) =>
          product.variants.map(
            (variant: any) => `gid://shopify/ProductVariant/${variant.id}`
          )
        )
        .flat();

      const query = `query getProductVariantNodes(
        $ids: [ID!]!
      ) {
        nodes(ids: $ids) {
          ... on ProductVariant {
            id
            display: metafield(namespace: "custom", key: "display_on_site") {
              value
            }
          }
        }
      }`;

      const data = await storefrontClient(query, { ids: variantsIds });

      const variantsDetails = data.data.nodes
        .filter((item: any) => item)
        .map((item: any) => ({
          id: item.id.replace('gid://shopify/ProductVariant/', ''),
          display: item.display?.value,
        }));

      this.productRecommendations = productRecommendations
        .map((product: any) => ({
          ...product,
          variants: product.variants.filter(
            (variant: any) =>
              variantsDetails.find(({ id }: any) => id == variant.id)
                ?.display === 'true'
          ),
        }))
        .filter((product: any) => product?.variants?.length);
    },
    async addFreeGift() {
      const freeGift = (Alpine.store('freeGift') as any).getAjaxData();

      if (freeGift) {
        await fetch('/cart/add.js', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(freeGift),
        });
      }
    },
    canAddFreeGift(additionalPrice = 0) {
      const threshold = (Alpine.store('freeGift') as any).thresholdInCents;

      return (
        threshold &&
        !this.freeGift &&
        this.data.items_subtotal_price + additionalPrice >= threshold
      );
    },
  } as any);
};
