import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.store('ajaxPage', {
    loading: false as boolean,
    content: {} as { [key: string]: string | null },

    async getContent(this: { loading: boolean; content: { [key: string]: string | null }; url: string | null }, url: string): Promise<void> {

      if(this.content[url] == null || this.content[url] == undefined) {
        try {
          await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "text/html; charset=utf-8",
            },
          })
            .then(response => {
              return response.text()
            })
            .then(html => {
              const parser = new DOMParser()
              const productDOM = parser.parseFromString(html, "text/html")
              const productDOMMain = productDOM.querySelector('main')?.innerHTML

              // @ts-ignore
              this.content[url] = productDOMMain;
            })
            .catch(error => {
              console.error('Failed to fetch page: ', error)
            })
        } catch (error) {
          console.error(error);
        } finally {
          // this.loading = false;
        }
      }
    },

    setContent(
      this: { loading: boolean; content: { [key: string]: string | null };
      url: string | null;
      getContent(url: string): void;
    }, url: string): void {
      this.loading = true;

      let insertHTMLToMain = ():void => {
        let main = document.querySelector('main')
        if (main) { // @ts-ignore
          main.innerHTML = this.content[url];
        }
        history.replaceState(null, '', url);

        this.loading = false;
      }

      // @ts-ignore
      if (this.content[url] != null && this.content[url] != undefined) {
        insertHTMLToMain();
      } else {
        let intervalID =  setInterval(() => {
          // @ts-ignore
          if (this.content[url] != null && this.content[url] != undefined) {
            insertHTMLToMain();

            clearInterval(intervalID);
          } else {
            this.getContent(url);
          }
        }, 100);
      }
    }
  })
}
