import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider, { BlazeConfig } from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('blaze', (props, isCentered) => ({
    showNavSlider: false,
    centerSlide: false,
    loaderHidden: false,
    slider: null as null | BlazeSlider,
    parentClass: '',
    productReviewCarousel: false,

    async init() {
      const { default: BlazeSlider } = await import('blaze-slider');
      this.slider = new BlazeSlider(this.$el, props as BlazeConfig);

      this.hideArrows(this.slider);
      if (isCentered) {
        this.centeringSlides(this.slider);
      }

      if (this.slider !== null) {
        this.hideLoader();
      }

      this.$el.classList.add('blaze-slider-initialized');

      if (this.productReviewCarousel) {
        this.extensionForProductReviewCarousel(this.parentClass, this.slider);
      }
    },

    centeringSlides(blazeSlider: BlazeSlider) {
      const slidesLength = blazeSlider.slides.length;
      const slidesToShow = blazeSlider.track.slider.config.slidesToShow;

      if (slidesLength <= slidesToShow) {
        this.centerSlide = true;
      }
    },

    hideLoader() {
      this.$nextTick(() => {
        this.loaderHidden = true;
      });
    },

    hideArrows(blazeSlider: BlazeSlider) {
      const slidesLength = blazeSlider.slides.length;
      const slidesToShow = blazeSlider.track.slider.config.slidesToShow;

      this.showNavSlider = slidesLength > slidesToShow;
    },

    extensionForProductReviewCarousel(parentClass: string, blazeSlider: BlazeSlider) {
      if (parentClass == '') return;

      const list = document.querySelectorAll(parentClass + ' .blaze-track > div');
      list[1].classList.add('active');

      const unsubscribe = blazeSlider?.onSlide(
        (pageIndex, firstVisibleSlideIndex, lastVisibleSlideIndex) => {
          const list = document.querySelectorAll(parentClass + ' .blaze-track > div');
          list.forEach(function(item, index) {
            if (index == 1) {
              item.classList.add('active');
            } else {
              item.classList.remove('active');
            }
          })
        }
      )
    }
  }));
};
