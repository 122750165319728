import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data("draggableScroll", () => ({
    x: 0,
    isDragging: false,

    init() {
      const mouseMoveHandler = (e: any) => {
        this.isDragging = true;
        this.$el.scrollTo(this.$el.scrollLeft + ((this.x - e.clientX) * 2), 0);
      };

      const mouseUpHandler = () => {
        this.$el.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      this.$el.addEventListener('mousedown', (e) => {
        e.preventDefault();
        this.isDragging = false;
        this.x = e.clientX;

        this.$el.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      });

      this.$el.addEventListener('click', (e) => {
        if (this.isDragging) {
          e.preventDefault();
          e.stopPropagation();
        }
      }, { capture: true });
    }
  }));
};
